.image-details {
  .ant-row {
    padding: 2rem;
    border-bottom: 2px solid whitesmoke;
  }
  label{
      color: grey;
  }
  .ant-tag{
      border-radius: 5px;
  }
}

.moderator-img{
  align-self: self-start;
}

