.nav {
  width: 100%;
  // max-width: 1240px;
  // padding-left: 5%;
  // padding-right: 5%;
  height: 60px;
  display: flex;
  align-content: center;

  // justify-content: space-between;
  // align-items: center;
}

.header {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #5cad46;
}

.header-container {
  display: flex;
  justify-content: center;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

.nav ul {
  list-style-type: none;
  padding: 1rem 0px;
  box-sizing: border-box;
  margin: 0px;
  font-size: 1em;
  text-align: right;
  font-weight: 100;
  display: flex;
  // align-items: center;
  // justify-content: flex-end;
}

.nav li {
  margin-left: 1.5rem;
  line-height: 3;
}

.nav a {
  color: white !important;
  font-weight: 500;
}

.nav a.is-active {
  color: #f9d469 !important;
}

.profile {
  font-weight: 600 !important;
}
