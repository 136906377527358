@import "~antd/dist/antd.css";

$card-border-radius: 20px;
$default-padding: 20px;

$offwhite: #f2f2f2;
$primary-green: #5cad46;
$primary-yellow: #f9d469;
$input-border: #949292;
$opacity-border: #dedede;
$light-border: rgba(10, 10, 10, 0.05);
$black: #1b2014;
$gold: #fcc201;
$silver: #c4d4e0;
$bronze: #cd7f32;

// Base Styling
body {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  /* font-size: 3.125rem; */
  font-size: 1.802rem;
  margin-bottom: 0em !important;
  font-weight: 800 !important;
}
h2 {
  /* font-size: 2.618rem; */
  font-size: 1.602rem;
  margin-bottom: 0em !important;
  font-weight: 800 !important;
}
h3 {
  // font-size: 1.931rem;
  font-size: 1.424rem;
  margin-bottom: 0em !important;
  font-weight: 700 !important;
}
h4 {
  // font-size: 1.618rem;
  font-size: 1.266rem;
  margin-bottom: 0em !important;
  font-weight: 700 !important;
}

h5 {
  //font-size: 1.194rem;
  font-size: 1.125rem;
  margin-bottom: 0em !important;
  font-weight: 600 !important;
}
h6 {
  font-size: 1rem;
  margin-bottom: 0em !important;
  font-weight: 500;
}

p,
span,
b {
  font-size: 0.889em;
}

p:last-child {
  margin-bottom: 0rem;
}

p,
span {
  font-weight: 400;
}

.anticon {
  font-size: inherit;
}

b {
  font-weight: 500 !important;
  line-height: 1.8rem;
}

/* Common Areas */

.container {
  // padding: 0% 5%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

.header-area {
  background-color: $offwhite;
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.subheader {
  background-color: $offwhite;
  box-shadow: 0px 0px 20px #0000001a;
  backdrop-filter: blur(50px);
  margin-bottom: 30px;

  .container {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom: 0px;
  }
}

.inner {
  padding: $default-padding;
}

.card {
  background: white;
  border-radius: $card-border-radius;
  margin-bottom: 1rem;
}

.justify-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.align-center {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

.inner-row {
  padding: 1rem 0rem 0.4rem 0rem;
}

.list-item {
  padding: 0.6rem 0rem;

  &:not(:last-child) {
    border-bottom: 1px solid $light-border;
  }
}

.submetrics {
  padding: 0rem 1.6rem;
  p {
    margin-bottom: 0px;
  }
  b {
    font-weight: 700 !important;
  }
}

.border-right {
  border-right: 1px solid $opacity-border;
}

.border-top {
  border-top: 1px solid $opacity-border;
}

.border-bottom {
  border-bottom: 1px solid $opacity-border;
}

/* Input and Forms */
.inline-form-item:not(:last-child) {
  margin-right: 1rem;
}

.input-with-border {
  border: 1px solid $input-border !important;
}

.ant-table-thead {
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.1);
}

.mini-table {
  .ant-table-thead {
    box-shadow: none !important;
  }
  thead.ant-table-thead th {
    border-left: 0;
  }
}
.mini-table-header {
  padding-bottom: 1em;
  font-size: 0.8rem;
  opacity: 0.6;
  p {
    font-weight: 500;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-layout-background {
  height: 100%;
}

.site-layout {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

@media screen and (min-width: 1500px) {
  .site-layout {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}

.app-area {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: #f7f7f7 !important;
}
.main-area {
  flex: 1;
}

.shadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
/*
.ant-btn-primary {
  background-color: black !important;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2) !important;
  border: 0 !important;
  color: #ffffff !important;
  transition: 0.2s;
}

.ant-btn {
  border-radius: 10px !important;
  font-weight: 500 !important;
}

.ant-btn-default {
  background-color: $primary-green !important;
  color: white !important;
}
*/

.ant-table {
  background: 0 !important;
}

Table {
  border-collapse: collapse !important;
}

.table-row {
  //border-bottom: 2px solid rgba(0, 0, 0, 0.06) !important;
}

td {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

thead.ant-table-thead th {
  background: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-weight: 600 !important;
}

thead.ant-table-thead th {
  text-align: center;
}

.page-sub-header {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 2rem !important;
}

.card {
  background-color: white !important;
}

.common {
  height: 45% !important;
}

.ant-tag {
  border-radius: 5px !important;
}

.ant-message-notice-content {
  border-radius: 10px !important;
}

.ant-form label {
  font-weight: 600 !important;
}

.ant-modal-content {
  border-radius: 20px !important;
}
.ant-modal-header {
  border-radius: 20px !important;
}

td.ant-picker-cell {
  padding: 0.5rem !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #5cad4660 !important;
  .ant-picker-cell-inner {
    border: none !important;
  }
}

.ant-picker-cell-range-end {
  // border-radius: 10px !important;
  .ant-picker-cell-inner {
    background-color: #5cad46 !important;
    border-radius: 13px !important;
    border: 0 !important;
  }
}
.ant-picker-cell-range-start {
  border: 0 !important;

  .ant-picker-cell-inner {
    background-color: #5cad46 !important;
    border-radius: 13px !important;
    border: none !important;
    outline: none !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  // background: #c4e0bb !important;
  border: none !important;
  outline: none !important;
}

.ant-picker-cell-range-hover {
  .ant-picker-cell-inner {
    // background: #c4e0bb!important;
    border: none !important;
    outline: none !important;
  }
}
.ant-picker-cell-range-hover::before {
  background: #c4e0bb !important;
  border: none !important;
  color: white;
  // position: absolute;
  // top: 50%;
  // right: 0;
  // left: 0;
  // z-index: 1;
  // height: 24px;
  // content: "";
  outline: none !important;
}

.pie-chart-legend {
  div {
    // float: left;
    // padding: 2px;
    margin-right: 10px;
    font-weight: 500;
  }
}

.recharts-text.recharts-pie-label-text {
  fill: black !important;
}

.filter {
  top: 60px;
  z-index: 1;
  position: sticky;
}

.content-wrap {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.content-wrap img {
  width: 100%;
  object-fit: cover;
  line-height: 1 !important;
  min-height: auto !important;
  max-height: none !important;
  height: auto !important;
  object-position: right;
}

.image-caption {
  // width: "100%";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.25); /* Black see-through */
  color: #f1f1f1;
  // width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 1rem;
  padding: 2px;
  margin: 5px;
  border-radius: 10px;
  text-align: right;
}

.overlay-video {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  // color: #f1f1f1;
  // width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 1rem;
  padding: 2px 5px 0px 5px;
  margin: 5px;
  border-radius: 10px;
  text-align: left;
}

// .container:hover .overlay {
//   opacity: 1;
// }

#validate {
  color: $primary-green;
}

.meta-data {
  color: $primary-green;
}

.moderator-rate {
  .ant-rate-star.ant-rate-star-zero {
    svg {
      color: #cecece !important;
    }
  }
}
.ant-card-body{
  padding: 0px !important;
  border-radius: 15px;
}
.ant-modal-header, .ant-modal-footer{
  border: none !important;
  text-align: center;
}
label{
  font-weight: 600;
  line-height: 38px;
  font-size: 18px;
}